<template>
  <div class="box">
    <el-tabs v-model="activeNames" @tab-click="handleClick" type="card">
      <el-tab-pane :label="item.name" :name="item.id" v-for="(item, index) in data_list" :key="index"></el-tab-pane>
    </el-tabs>
    <!-- 文件上传 -->
    <div style="display: flex">
      <transition name="el-zoom-in-top">
        <div v-show="show2" class="transition-box">
          <div>
            <el-row :gutter="5">
              <el-col :span="40">
                <div class="grid-content bg-purple">
                  <span style="color: black"><span style="color: red">*</span> 排查清单类型 :
                  </span>
                  <el-select v-model="value1" placeholder="请选择" @change="all_add">
                    <el-option placeholder v-for="item in pcqd" :key="item.value" :label="item.label" :value="item.label">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="40" :offset="3">
                <div class="grid-content bg-purple">
                  <span style="color: black"><span style="color: red">*</span> 检查类型 :
                  </span>
                  <el-select v-model="value2" placeholder="请选择">
                    <el-option placeholder v-for="item in jclx" :key="item.value" :label="item.label" :value="item.label">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="40">
                <div class="grid-content bg-purple" style="padding-left: 33px">
                  <span style="color: black">
                    <span style="color: red">*</span> 组织机构 :
                  </span>
                  <el-select v-model="value3" placeholder="请选择" @change="all_adda">
                    <el-option style="padding-left: 14px" placeholder v-for="item in gwcj" :key="item.index"
                      :label="item.Department_name" :value="item.Department_name">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="40" :offset="3">
                <div class="grid-content bg-purple" style="padding-left: 15px">
                  <span style="color: black">
                    <span style="color: red">*</span>负责人 :
                  </span>
                  <el-select v-model="value4" placeholder="请选择">
                    <el-option placeholder v-for="item in fzr" :key="item.index" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>

            <div style="width: 500px">
              <span style="color: black">
                <span style="color: red">*</span>任务时间 ：
              </span>
              <el-date-picker v-model="value6" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div style="width: 380px; padding-top: 40px">
              <!-- <el-button type="info" @click="hidd_get"  >添加检查内容</el-button> -->
              <el-button type="primary" icon="el-icon-search" @click="hidd_get">检查内容</el-button>
            </div>
          </div>

          <el-button @click="
            TableDisplay = false
          hidden = true;
          show2 = false;
          show3 = false;
          tableData = [];
          value1 = '';
          value2 = '';
          value3 = '';
          value4 = '';
          value6 = '';
          ">取消</el-button>

          <el-button @click="conls">添加检查任务</el-button>
        </div>
      </transition>
      <transition name="el-zoom-in-center">
        <div v-show="show3" class="transition-boxs">
          <el-table :data="tableData" border style="width: 100%" height="750" @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#E5E9F4', color: '#6012f4' }">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="作业活动类别" width="120" :filters="lei" :filter-method="filterTag">
            </el-table-column>
            <el-table-column prop="riskname" label="名称" width="70">
            </el-table-column>
            <el-table-column prop="riskrank" label="风险等级" width="90" :filters="[
              { text: '重大风险', value: '重大风险' },
              { text: '较大风险', value: '较大风险' },
              { text: '一般风险', value: '一般风险' },
              { text: '低风险', value: '低风险' },
            ]" :filter-method="filterTagse">
            </el-table-column>
            <el-table-column prop="accountability" label="责任单位" width="100" :filters="[
              { text: '公司级', value: '公司级' },
              { text: '项目级', value: '项目级' },
              { text: '班组级', value: '班组级' },
              { text: '作业人员', value: '作业人员' },
            ]" :filter-method="filterTags">
            </el-table-column>
            <el-table-column prop="risktype" label="作业步骤">
            </el-table-column>
            <el-table-column prop="detailss.hazard" label="主要危险有害因素（人、物、环、管）" show-overflow-tooltip width="300">
            </el-table-column>
            <el-table-column prop="detailss" label="管理措施" width="600" show-overflow-tooltip>
              <template slot-scope="scope">
                <div>
                  <ul>
                    <li>管控措施： {{ scope.row.detailss.controls }}</li>
                    <li>应急措施： {{ scope.row.detailss.manager }}</li>
                    <li>个人措施：{{ scope.row.detailss.personage }}</li>
                    <li>工程措施： {{ scope.row.detailss.projectrola }}</li>
                  </ul>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="hidden" :label="this.value2" align="center">
              <i class="el-icon-check"></i>
            </el-table-column>
          </el-table>
          <!-- <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[5, 10,20,50,]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count"
          >
          </el-pagination> -->

          <el-button @click="show3 = false">取消</el-button>
          <el-button @click="add_alls(3)">确定</el-button>
        </div>
      </transition>
      <transition name="el-zoom-in-center">
        <div v-show="show4" class="transition-boxs">
          <el-table :data="tableData" border style="width: 100%" height="750" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="pro_name" label="排查项目" width="250">
            </el-table-column>
            <el-table-column prop="Management_content" label="排查内容与排查标准" width="250">
            </el-table-column>
            <el-table-column prop="hidden" label="项目周检" align="center">
              <i class="el-icon-check"></i>
            </el-table-column>
          </el-table>
          <!-- <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[5, 10, 50, 100, 200, 300]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count"
          >
          </el-pagination> -->

          <el-button @click="show4 = false">取消</el-button>
          <el-button @click="add_alls(4)">确定</el-button>
        </div>
      </transition>
    </div>
    <el-table v-if="TableDisplay" :data="data_lists" border style="width: 100%" height="750"
      @selection-change="handleSelectionChange" :header-cell-style="{ background: '#E5E9F4', color: '#6012f4' }">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="name" label="作业活动类别" width="120" :filters="lei" :filter-method="filterTag">
      </el-table-column>
      <el-table-column prop="riskname" label="名称" width="70">
      </el-table-column>
      <el-table-column prop="riskrank" label="风险等级" width="90" :filters="[
        { text: '重大风险', value: '重大风险' },
        { text: '较大风险', value: '较大风险' },
        { text: '一般风险', value: '一般风险' },
        { text: '低风险', value: '低风险' },
      ]" :filter-method="filterTagse">
      </el-table-column>
      <el-table-column prop="accountability" label="责任单位" width="100" :filters="[
        { text: '公司级', value: '公司级' },
        { text: '项目级', value: '项目级' },
        { text: '班组级', value: '班组级' },
        { text: '作业人员', value: '作业人员' },
      ]" :filter-method="filterTags">
      </el-table-column>
      <el-table-column prop="risktype" label="作业步骤">
      </el-table-column>
      <el-table-column prop="detailss.hazard" label="主要危险有害因素（人、物、环、管）" show-overflow-tooltip width="300">
      </el-table-column>
      <el-table-column prop="detailss" label="管理措施" width="600" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>
            <ul>
              <li>管控措施： {{ scope.row.detailss.controls }}</li>
              <li>应急措施： {{ scope.row.detailss.manager }}</li>
              <li>个人措施：{{ scope.row.detailss.personage }}</li>
              <li>工程措施： {{ scope.row.detailss.projectrola }}</li>
            </ul>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="hidden" :label="this.value2" align="center">
        <i class="el-icon-check"></i>
      </el-table-column>
    </el-table>

    <div class="add_work" v-if="hidden">
      <div style="width:20px;height:20px;margin-right: 5px;" @click="dialogVisible = true" v-if="isyincang">
        <img src="../../../assets/img/R-C.png" alt="" style="width:20px;height:20px">
      </div>
      <el-button type="primary" @click="show2 = true,
        hidden = false;" plain round v-if="isyincang">添加检查任务</el-button>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <div class="tishi">
        添加检查任务前请确认：
      </div>
      <div class="tishi">
        1、组织机构管理已经完善过该负责人
      </div>
      <div class="tishi">
        2、项目管理中已经添加人员，并且在人员列表中已经存在该人员
      </div>
      <div class="tishi">
        另：添加检查任务中“组织机构”需选择该负责人的所属层级的上一级
      </div>
      <div class="tishi">
        例如：生产负责人：张三
      </div>
      <div class="tishi">
        “组织机构”就需要选择生产负责人的上一级：项目部
      </div>
      <div>
        <img src="../../../assets/img/tixing1(1).png" alt="" style="width: 80%;">
      </div>
      <div>
        <img src="../../../assets/img/tixing2(1).png" alt="" style="width: 80%;">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <div v-if="hidden">
      <el-table :data="tableDatas" border style="width: 100%">
        <el-table-column prop="Screentype" label="排查类型" width="180" style="background-color: #0052a7">
        </el-table-column>
        <el-table-column prop="start_time" label="开始时间" width="180">
        </el-table-column>
        <el-table-column prop="end_time" label="结束时间"> </el-table-column>
        <el-table-column prop="Screeningarea" label="排查区域">
        </el-table-column>
        <el-table-column prop="principal" label="负责人"> </el-table-column>
        <el-table-column prop="task_status" label="任务状态"> </el-table-column>
        <el-table-column prop="Screeningcycle" label="排查周期">
        </el-table-column>
        <el-table-column prop="creator" label="创建人"> </el-table-column>
        <el-table-column prop="create_time" label="创建时间"> </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button> -->
            <el-button type="text" @click="del_data(scope.row.id)">删除</el-button>
            <el-button type="text" @click="show_data(scope.row.id)">查看</el-button>
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="检查内容" :visible.sync="show_table_data" width="60%">
        <el-table :data="tablesDatas" border style="width: 100%" height="500" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="activity_category" label="作业活动类别" width="140">
          </el-table-column>
          <el-table-column prop="name" label="名称" width="110px">
          </el-table-column>
          <el-table-column prop="risk_rank" label="风险等级" width="80px">
          </el-table-column>
          <el-table-column prop="accountability" label="责任单位" width="80px">
          </el-table-column>
          <el-table-column prop="job" label="作业步骤" width="100px">
          </el-table-column>
          <el-table-column prop="Majorhazard" label="主要危险有害因素（人、物、环、管）" show-overflow-tooltip width="209px">
          </el-table-column>
          <el-table-column prop="Majorhazard" label="管理措施" width="250px" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                <ul>
                  <li>管控措施： {{ scope.row.measures.controls }}</li>
                  <li>应急措施： {{ scope.row.measures.manager }}</li>
                  <li>个人措施：{{ scope.row.measures.personage }}</li>
                  <li>工程措施： {{ scope.row.measures.projectrola }}</li>
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="hidden" :label="this.value2" align="center" width="50px">
            <i class="el-icon-check"></i>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="show_table_data = false">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="检查内容" :visible.sync="show_table_datas" width="60%">
        <el-table :data="tablesDatass" border style="width: 100%" height="500" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="activity_category" label="作业活动类别" width="495px">
          </el-table-column>
          <el-table-column prop="name" label="名称" width="495px">
          </el-table-column>
          <el-table-column prop="hidden" :label="this.value2" align="center" width="50px">
            <i class="el-icon-check"></i>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="show_table_datas = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  addUpload,
  uniapp_get,
  uniapp_post,
  Get_Risk_data_hidd,
  Clear_Check_the_task,
  Clear_Check_the_task_show,
  Clear_Check_the_task_del,
  datas_tables_list,
} from "../../../request/report";
import {
  personnelInformation
} from "../../../request/risk";
export default {
  data() {
    return {
      data_list: [],
      activeNames: '',
      dialogVisible: false,
      TableDisplay: false,
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海'
      }],
      hidden: true,
      lei: [],
      tablesDatas: [],
      tablesDatass: [],
      show_table_data: false,
      show_table_datas: false,
      count: 0,
      sizes: 5,
      pages: 1,
      currentPage4: 1,
      show2: false,
      show4: false,
      show3: false,
      tableData: [],
      tableDatas: [],
      fzr: [],
      gwcj: [
        {
          value: "1",
          label: "黄金糕",
        },
      ],
      jclx: [],
      pcqd: [
        {
          value: "1",
          label: "基础管理类排查清单",
        },
        {
          value: "2",
          label: "作业活动类排查清单",
        },
        {
          value: "3",
          label: "设备设施类排查清单",
        },
      ],
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value6: "",
      formInline: {
        user: "",
        region: "",
      },
      data_lists: [],
      isyincang: true,
      pjtid:'',
    };
  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.show2= false,
      this.hidden = true
      this.personnelInformation()
      if (localStorage.getItem('rank') == 4) {
      this.isyincang = false
    }
    },
  },
  created() {
    this.personnelInformation()
  },
  mounted() {
    if (localStorage.getItem('rank') == 4) {
      this.isyincang = false
    }
  },
  methods: {
    //通过项目id获取人员信息
    async personnelInformation() {
      let msg = {
        unique_identification: localStorage.getItem("uid"),
      }
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project') 
        }else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project') 
        }
      }
      const res = await personnelInformation(msg)
      console.log(res, '通过项目id获取人员信息');
      if (res.data.data.length > 0) {
        this.data_list = res.data.data
        this.activeNames = res.data.data[0].id
      }
      this.show_all();
      this.getcompany();
    },
    handleClick(tab, event) {

      this.TableDisplay = false
      this.hidden = true;
      this.show2 = false;
      this.show3 = false;
      this.tableData = [];
      this.value1 = '';
      this.value2 = '';
      this.value3 = '';
      this.value4 = '';
      this.value6 = '';
      this.show_all();
      this.getcompany();
    },
    filterTag(value, row) {
      return row.name === value;
    },
    filterTags(value, row) {
      return row.accountability === value;
    },
    filterTagse(value, row) {
      return row.riskrank === value;
    },
    async show_data(e) {
      // console.log(e);

      var msg = {
        id: e,
      };
      const { data: res } = await datas_tables_list(msg);
      // console.log(res.data);
      if (res.data[0].Majorhazard == "基础管理") {
        // console.log("基础管理类表格");
        this.tablesDatass = res.data;

        this.show_table_datas = true;
      } else {
        this.tablesDatas = res.data;

        this.show_table_data = true;
      }
    },
    async del_data(e) {
      // console.log(e);

      var msg = {
        id: e,
      };
      const { data: res } = await Clear_Check_the_task_del(msg);
      // console.log(res.data);

      this.show_all();
    },
    async show_all() {
      var msg = {
        pro_id: this.activeNames,
      };
      const { data: res } = await Clear_Check_the_task_show(msg);
      this.tableDatas = res.data;
      // console.log(this.tableDatas,'这个是什么')
    },
    add_alls(valu) {
      if (this.data_lists.length == 0) {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择排查内容",
          type: "warning",
        });
        return false;
      } else {
        if (valu == 3) {
          this.show3 = false;
          this.TableDisplay = true
        } else if (valu == 4) {
          this.show4 = false;
          this.TableDisplay = false;
        }
      }
    },
    async handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.sizes = val;
      var msg = {
        pro_id: localStorage.getItem("corporations"),
        page: this.currentPage4,
        size: val,
        hd: this.value1,
        Screen_type: this.value2,
      };
      const { data: res } = await Get_Risk_data_hidd(msg);
      // console.log(res.data);
      this.count = res.count;
      this.lei = res.data_kei;
      // console.log(this.lei);
      this.tableData = res.data;
    },
    async handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      var msg = {
        pro_id: localStorage.getItem("corporations"),
        page: val,
        size: this.sizes,
        hd: this.value1,
        Screen_type: this.value2,
      };
      const { data: res } = await Get_Risk_data_hidd(msg);
      // console.log(res.data);
      this.count = res.count;
      this.lei = res.data_kei;
      this.tableData = res.data;
    },
    handleSelectionChange(val) {
      console.log(val, '1');
      this.data_lists = val;
    },
    all_add(e) {
      this.value2 = "";
      if (e == "基础管理类排查清单") {
        this.jclx = [
          {
            value: "1",
            label: "项目周检",
          },
        ];
      } else {
        this.jclx = [
          {
            value: "1",
            label: "日常班中交接班",
          },
          {
            value: "2",
            label: "日常班中巡检",
          },
          {
            value: "3",
            label: "项目周检",
          },
        ];
      }
    },
    async all_adda(e) {
      var msg = {
        name: e,
        pro_id: this.activeNames,
      };
      const { data: res } = await uniapp_post(msg);
      this.value4 = "";
      this.fzr = res.data;
    },
    async getcompany() {
      var msg = {
        pro_id: this.activeNames,
      };
      const { data: res } = await uniapp_get(msg);
      console.log(res.data);
      this.gwcj = res.data;
    },
    async conls() {
      var moment = require("moment");
      console.log(localStorage.getItem("corporations"));
      console.log(this.value1);
      console.log(this.value2);
      console.log(this.value3);
      console.log(this.value4);
      console.log(moment(this.value6[0]).format().split("T")[0]);
      console.log(moment(this.value6[1]).format().split("T")[0]);
      console.log(this.data_lists);

      if (moment(this.value6[1]).format().split("T")[0] == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择时间",
          type: "warning",
        });
        return false;
      }
      if (moment(this.value6[0]).format().split("T")[0] == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择时间",
          type: "warning",
        });
        return false;
      }
      if (this.value1 == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择排查清单类型",
          type: "warning",
        });
        return false;
      }
      if (this.value2 == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择检查类型",
          type: "warning",
        });
        return false;
      }
      if (this.value3 == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择组织机构",
          type: "warning",
        });
        return false;
      }
      if (this.value4 == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择负责人",
          type: "warning",
        });
        return false;
      }
      if (this.data_lists.length == 0) {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择排查内容",
          type: "warning",
        });
        return false;
      }
      var principal_id = 0;
      for (var i = 0; i < this.fzr.length; i++) {
        if (this.fzr[i].name == this.value4) {
          principal_id = this.fzr[i].uid;
        }
      }
      var msg = {
        pro_id: this.activeNames,
        Screentype: this.value2,
        start_time: moment(this.value6[0]).format().split("T")[0],
        end_time: moment(this.value6[1]).format().split("T")[0],
        principal: this.value4,
        principal_id: principal_id,
        accountability: "班组级",
        creator: localStorage.getItem("username"),
        data: this.data_lists,
      };
      const { data: res } = await Clear_Check_the_task(msg);
      console.log(res);
      if (res.code == "200") {
        this.$notify({
          title: "成功",
          message: "任务添加成功",
          type: "success",
        });
        this.hidden = true;
        this.TableDisplay = false;
      } else {
        this.$notify.error({
          title: "错误",
          message: "添加失败了看看是什么原因",
        });
      }
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show_all();

      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value6 = "";
      this.data_lists = [];
    },
    async hidd_get() {
      this.TableDisplay = false;
      if (this.value1 == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择排查清单类型",
          type: "warning",
        });
        return false;
      }
      if (this.value2 == "") {
        this.$notify({
          title: "警告",
          message: "添加失败，你没有填选择检查类型",
          type: "warning",
        });
        return false;
      }
      if (this.value1 == "基础管理类排查清单") {
        this.show3 = false;
        this.show4 = true;
      } else {
        this.show4 = false;
        this.show3 = true;
      }
      var msg = {
        pro_id: this.activeNames,
        page: 1,
        size: 999999,
        hd: this.value1,
        Screen_type: this.value2,
      };
      const { data: res } = await Get_Risk_data_hidd(msg);
      // console.log(res,'是全部数据吗?');
      this.tableData = res.data;
      this.count = res.count;

      this.lei = res.data_kei;
      console.log(this.lei);
    },
  },
};
</script>

<style lang="less" scoped>
.add_work {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
}

.transition-box {
  margin-bottom: 10px;
  width: 800px;
  // height: 880px;
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}

.transition-boxs {
  position: absolute;
  right: 0px;
  margin-bottom: 10px;
  // height: 880px;
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
  // z-index: 999999;
}

.pd1 {
  padding-left: 100px;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #ffffff;
}

.bg-purple {
  background: #ffffff;
}

.bg-purple-light {
  background: #ffffff;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #ffffff;

}

.tishi {
  color: rgb(192, 0, 0);
  font-size: 18px;
  line-height: 30px;
}
</style>  
